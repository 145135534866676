<template>
<div style="margin-top: 80px">
    <BasePageBreadCrumb :pages="pages" :title="`${users.length} usuários`" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                <button type="submit" class="btn btn-info" @click="fetchUsers">Buscar</button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                            <div class="form-group d-flex">
                                <b-link :to="{name: 'UserNew'}" class=" ml-auto">
                                    <button type="submit" class="btn btn-dark"><i class="fas fa-street-view mr-2"></i>Novo usuário</button>
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
            <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                            <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                        <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-8 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Nome</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">E-mail</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Whatsapp</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="item in users" :key="item.id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status  d-none d-lg-block">
                                            <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <img v-if="item.image" :src="item.image" alt="user" class="rounded-circle card-body-list-img" />
                                        </div>
                                        <div class="col-7 col-md-6 col-lg-8 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="text-truncate cod">Art Residence</h6>
                                                        <h6 class="text-truncate card-body-list-title">{{ item.first_name }} {{ item.last_name }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <h6 class="text-truncate e-mail">{{ item.email }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="text-truncate whats">{{ item.cellphone }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <b-link :to="{name: 'UserEdit', params: {id: item.id}}" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" >+</b-link>
                                            <a class="btn btn-success ml-1 text-white rounded-circle btn-circle font-20 card-body-list-cta" target="_blank" :href="`https://api.whatsapp.com/send?phone=${item.cellphone.replace(/\D/g,'')}`"><i class="fab fa-whatsapp font-20"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-center p-4" v-if="users.length == 0">
                        Nenhum síndico encontrado.
                    </div>

                </div>
                <div class="col-12">
                    <BasePagination
                        v-if="users.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import QAdminService from "@/services/resources/QAdminService";
const service = QAdminService.build();

export default {
    data(){
        return{
            users: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            search: null,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'usuários',
                    to: 'User'
                }
            ],
            menu:[
                {
                    name: 'Usuários',
                    to: 'User'
                },
                {
                    name: 'Novo usuário',
                    to: 'UserNew'
                }
            ]
        }
    },
    methods:{
        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchUsers();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;
            this.fetchUsers();
        },
        fetchUsers(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.search){
                data.search = this.search;
            }

             service
                .search(data)
                .then(resp => {
                    this.users = resp.data;
                    this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
                })
                .catch(err => {
                console.log(err)
                })
        }
    },
    mounted(){
        this.fetchUsers();
    }
}
</script>